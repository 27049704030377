import { reactive, ref, watch } from 'vue';
import { apiEgpmDownloadPage } from '@/request/egpmMaterialApi';
import { useRoute } from 'vue-router';

// 判断是否过期
function isExpired(expireTime) {
    const now = new Date().getTime();
    const expire = new Date(expireTime).getTime();
    return now > expire;
}

export default () => {
    const route = useRoute();
    const query = reactive({
        reportConfigId: route.query.reportId,
        keyword: '',
        pageNum: 1,
        pageSize: 10
    })
    const total = ref(0);
    const tableData = ref([]);

    // 获取数据
    async function getTableData() {
        const { data } = await apiEgpmDownloadPage(query);
        total.value = data?.total || 0;
        tableData.value = data?.list || [];
        tableData.value = tableData.value.map(item => {
            item.isExpired = item.expireTime ? isExpired(item.expireTime) : false;
            return item;
        })
    }

    // 分页
    watch(() => query.pageNum, getTableData)

    // 搜索
    function search() {
        query.pageNum = 1;
        getTableData();
    }

    getTableData();

    return { query, total, tableData, search, getTableData }
}