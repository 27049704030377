<template>
    <div class="container">
        <!-- 筛选 -->
        <div class="row_box">
            <div class="search_box">
                <h1>导出下载</h1>
                <div class="filter">
                    <div class="li">
                        <el-input type="text" placeholder="标题" v-model.trim="query.keyword" maxlength="50" @keydown.enter="search">
                            <template #append><el-button :icon="Search" @click="search" /></template>
                        </el-input>
                    </div>
                </div>
            </div>
        </div>
        <!-- 表格 -->
        <div class="row_box">
            <div class="table_box">
                <el-table :data="tableData" stripe border style="width: 100%" :header-cell-style="{ background: '#f5f5f5', color: '#80899b' }" height="550">
                    <el-table-column prop="taskName" label="标题" />
                    <el-table-column prop="analysis" label="分析维度" />
                    <el-table-column prop="createTime" label="时间" />
                    <el-table-column prop="downloadTaskState" label="状态">
                        <template #default="scope">
                            <template v-if="scope.row.isExpired">
                                <el-tag type="warning">已过期</el-tag>
                            </template>
                            <template v-else>
                                <el-tag v-if="scope.row.downloadTaskState === 0">文档生成中</el-tag>
                                <el-tag v-if="scope.row.downloadTaskState === 1">文档生成中</el-tag>
                                <el-tag type="success" v-if="scope.row.downloadTaskState === 2">文档生成成功</el-tag>
                                <el-tag type="danger" v-if="scope.row.downloadTaskState === 3">文档生成失败</el-tag>
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column prop="id" label="操作" width="150">
                        <template #default="scope">
                            <el-link type="primary" @click="onDownload(scope.row)" v-if="scope.row.downloadTaskState === 2 && !scope.row.isExpired">下载</el-link>
                            <el-link type="danger" @click="onDelete(scope.row)">删除</el-link>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="page_box">
                    <el-pagination layout="prev, pager, next" background :total="total" :page-size="query.pageSize" v-model:current-page="query.pageNum"></el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import useTableData from "./useTableData";
import useDelete from "./useDelete";
import useDownload from "./useDownload";
import { Search } from "@element-plus/icons-vue";

// 表格数据
const { query, total, tableData, search, getTableData } = useTableData();

// 删除
const { onDelete } = useDelete(getTableData);

// 下载
const { onDownload } = useDownload();
</script>

<style lang="scss" scoped>
.container {
    padding: 0 40px 40px 40px;
    .row_box {
        background: #fff;
        border-radius: 14px;
        padding: 20px;
        margin-bottom: 10px;

        .tit_box {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .search_box {
            display: flex;
            align-items: center;
            padding: 0 20px;
            h1 {
                margin-right: 30px;
                color: #333;
                font-size: 20px;
            }
            .filter {
                display: flex;
                flex-wrap: wrap;

                .li {
                    margin-right: 20px;

                    h6 {
                        font-weight: normal;
                        color: #818a9b;
                        padding-bottom: 6px;
                        font-size: 14px;
                    }

                    :deep(.el-input__inner) {
                        width: 300px;
                        border-radius: 4px;
                    }
                    &.btn {
                        padding-top: 23px;
                    }
                }
            }
        }

        .btn_box {
            text-align: center;
            padding-top: 10px;
        }

        .table_box {
            position: relative;

            .no_data {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                text-align: center;
                padding-top: 180px;
            }
            .page_box {
                display: flex;
                justify-content: flex-end;
                padding-top: 20px;
            }
            .el-link {
                margin-right: 15px;
            }
        }
    }
}
</style>
